    // Here you can add other styles
.custTiPicker .react-datepicker-wrapper {
        width: 100%;
    }
    .custTiPicker .react-datepicker-wrapper input {
        height: 40px;
        border-radius: 4px;
    }
    body{
        font-family: $font-family-globel;
    }
    img.logoTest {
        padding-top: 0px;
        width: 130px;
        padding-bottom: 20px;
    }
    main.pageContent.headerTransparent {
        margin-top: -155px;
    }
	.staffAndPerformers .reportData td{
		text-transform: capitalize;
		}
    .custTicketOnlyGuestHeading .col {
        font-size: 16px;
        font-weight: 700;
    }
    .requiredFeild{
        position: relative;
    }
    .requiredFeild .custErrorMesg {
        right: 35px;
    }
    .tickOnlyGuestWraper{
        display: flex;
    }
    .tickOnlyGuestWraper input {
        border-radius: 0;
    }
    .tickOnlyGuestWraper button.subBtn {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .tickOnlyGuestWraper button.addBtn {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .su-list ul {
        list-style: none;
        padding: 0;
    }
    .su-list ul li {
        display: flex;
        gap: 10px;
        line-height: 29px;
        align-items: center;
    }
    .btn-primary{
        background: $darkBlue;
    }
    .bg-primary{
        background-color: $darkBlue !important;  
    }
    .primaryColor{
        color:$darkBlue;
    }
    .whiteColor{
        color:$white;
    }
    .packageDetailsBody .pacl {
        text-transform: capitalize;
        font-weight: 700;
        color: #000;
    }
    .packageDetails th {
        font-weight: 700;
        color: #000;
        font-size: 17px;
    }
    .btn-danger{
        color:$white;
    }
    img.rosterImage {
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
    .btn-primary{
        background: $yellow;
        color:$white;
        border-color: $yellow;
    }
    .btn-primary:hover{
        background: $yellow-800;
        border-color: $yellow;
    }
    .btn-success{
        background: $darkBlue;
        color:$white;
        border-color: $darkBlue;
    }

    .btn-success:hover{
        background: $darkBlueOp5;
        color:$white;
    }
    .collapse:not(.show) {
        display: none;
    }
    .headerTopBar {
        width: 100%;
    }
    ul._top_bar_social {
        display: flex;
        list-style: none;
        justify-content: end;
        margin-bottom: 0;
    }
    .aboutSecondSection {
        overflow: hidden;
        margin: 100px 0 !important;
    }
    .aboutSecondSection .leftSide {
        text-align: center;
    }
    ._top_bar_social li {
        padding: 0 5px;
    }
    .mainHeader {
        background: rgba(4,13,30,0.3);
        color: $white;
        margin-bottom: 0px !important;
        border: 0px !important;
    }
    .headerTransparent {
        margin-top: -160px;
    }
    .homePage .mainHeader {
        background: transparent;
    }
    .mainHeader.bg-trans {
        background: $darkBlue;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    }
    .homePage.headerTransparent {margin-top: -220px;}
    .mainHeader li {
        color: $white;
    }
    .aboutUs {
        background-color: #fff !important;
    }
    .error {
        color: red;
        margin-bottom: 0;
        font-weight: 600;
    }
    .mainHeader li a ,
    .footer a,
    .footer 
    {
        color: $white;
        text-decoration: none;

    }
    .BarcodeTopTitle {
        text-align: center;
    }

    .col.pr-0.camInputarea {
        display: flex;
        flex-direction: column;
        max-width: 380px;
        justify-content: center;
        margin: 0 auto;
    }

    .top_bar_phone_no a {
        color: $white;
        text-decoration: none;
    }
    a.nav-link {
        font-size: 18px;
        color: $white !important;
        font-weight: 600;
        padding: 0;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .mainHeader .header-nav {
        align-items: center;
    }
    .mainHeader .nav-link.logoForDasktop img {
        width: 100px;
    }
    // Footer Css
    .footer{
        background: $darkBlue;
    }
    .footerNavBar {
        padding-top: 15px;
        border-bottom: 3px solid $yellow;
        margin-bottom: 15px;
    }
    // End Footer Css


    // Event  admin Css
    .removeBtn {
        text-align: end;
    }
    .nav-tabs {
        background:  $darkBlue;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .nav-tabs .nav-link {
        padding: 5px 15px;

        }
    .nav-tabs .nav-link.active {
        background:  $yellow;  
        border-color: $white;
        }

    .custRoomRate label {
            font-size: 12px;
            font-weight: 700;
        }    
        .tab-content {
            margin-bottom: 30px;
        }
        .pclWrapper {
            background: $white;
            margin: 25px 0;
        }
        .custControl {
            text-align: right;
            padding-right: 0;
        }
        .package_Info_section {
            padding-top: 50px;
            padding-bottom: 50px;
            margin-top: -8px;
        }
        h2.event_title {
            font-size: 35px;
            text-align: center;
            padding: 30px 0;
            color: $yellow;
            margin-bottom: 0;
            font-weight: 400;
            text-transform: uppercase;
        }
        .packageGrid {
            background: $white;
            color: $black; 
            margin-bottom: 30px; 
        }
        .packagImage img {
            height: 215px;
            width: 100%;
            object-fit: cover;
        }
        ._package_title {
            text-align: center;
            padding: 18px 15px;
            background-color: $yellow;
            /* color: #fff; */
            position: relative;
        }
        ._package_title h3 {
            font-size: 21px;
            font-weight: 700;
            display: inline-block;
            background-color: $yellow;
            position: relative;
            z-index: 2;
            padding: 0 15px;
        }
        ._package_title:after {
            content: '';
            position: absolute;
            width: calc(100% - 40px);
            border-bottom: 1px solid #000;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: 48%;
        }
        ._package_count {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-color: $yellow;
            display: inline-block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 27px;
            font-weight: 700;
            border-radius: 50%;
            bottom: -25px;
        }
        .packageGrid  .card-body {
            text-align: center;
            padding: 1.25rem;
            padding-top: 50px;
        }
        .packageGrid .packagDese {
            min-height: 270px;
        }
        ._package_price {
            font-size: 20px ;
            font-weight: 600 ;
            position: relative;
        }
        ._package_price:before{
            position: absolute;
            content: '';
            width: 140px;
            height: 2px;
            background-color: $darkBlue;
            top:-2px;
            left: calc(50% - 70px)
        }
        ._price_details_per {
            font-size: 16px;
            font-style: italic;
        }
        .PackNavigatSection {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
        .packageGrid .PackNavigatSection button {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }
    // Event  admin Css
    // BooK Now
    .ReportDataWrapper {
        overflow-x: scroll;
    }
    .reportData th {
        font-size: 10px;
        font-weight: 400;
    }
    .sidebar-title h2 {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    .addonList .rommName {
        max-width: 71px;
        flex: 71px;
    }
    .subTitle h3 {
        font-size: 20px;
        font-weight: 700;
    }
    .innerWraper {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
    }

    .innerWraper .iconWrapper {
        flex: 50px;
    }

    .innerWraper .contentWraper {
        flex: calc(100% - 50px);
        margin-bottom: 15px;
    }
    .contentWraper {
        margin-bottom: 15px;
    }

    .iconWrapper svg {
        width: 40px !important;
        height: 40px !important;
    }
    .sidebar-content .innerContent p {
        margin-bottom: 0;
        font-size: 18px;
    }
    #progress.nav-tabs {
        background: transparent;
        border-color: $yellow;
    }
    ul#progress {
        border: none;
        cursor: pointer;
    }
    #progress li a {
        display: inline-block;
        padding: 14px 11px 14px 33px;
        color: #fff;
        position: relative;
        width: 100%;
        font-size: 14px;
        line-height: 28px;
        cursor: pointer;
        margin-top: 0px;
        text-transform: capitalize;
        border-radius: 0;
        border: 0px;
        background-color: $darkBlue;
        pointer-events: none;
    }
    #progress li a:before {
        content: "";
        border-left: 30px solid rgb(235, 235, 235);
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        position: absolute;
        top: -2px;
        left: 0px;
    }

    #progress li a:after {
        content: '';
        border-left: 28px solid $darkBlue;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        position: absolute;
        top: 0;
        left: 99%;
        z-index: 1;
    }
    input#checkIn ,
    input#checkOut{
        width: 27px;
        height: 27px;
        margin-top: 0;
        border: 2px solid;
        margin-right: 6px;
    }
    #progress.nav-tabs .nav-link.active{
        background: $yellow !important;
    }
    .addStaffPerformers .a_party {
        display: none;
    }
    #progress li a.active:after {
        border-left-color: $yellow !important;
    }

    .tabFooter {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .tabFooter.select_packages {
        justify-content: end;
    }
    .BookingGuest .hidep {
        display: none;
    }
    .booking-part-title h3 {
        background-color: $yellow;
        display: inline-block;
        padding: 10px 15px;
        font-size: 16px;
        color: #fff;
        margin: 15px 0;
    }
    // End Book Now
    // Start Booking Package Style

    .tabContent.packageList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .packageWraper {
        display: flex;
        flex: 0 0 calc(50% - 40px);
    }
    .tabContent.packageList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .packageWraper {
        display: flex;
        flex: 0 0 calc(50% - 15px);
        margin-bottom: 15px;
        background: $darkBlue;
    }

    .packageWraper .packageImage {
        flex: 35%;
        max-width: 35%;
    }

    .packageWraper .packageContent {
        flex: 100%;
        max-width: 100%;
    }
    .packageInfo {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        color: #fff;
        align-items: center;
    }
    .rangeWrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }
    .packageImage img {
        width: 100%;
        height: 100%;
        object-fit: COVER;
    }
    .number-range input {
        font-size: 33px;
        color: #0c2238;
        position: relative;
        right: 2px;
        width: 100%;
        background-color: transparent;
        font-weight: 800;
        padding: 0;
        max-width: 45px;
        border: 0px;
        margin-left: 10px;
        pointer-events: none;
    }
    .number-range button {
        background: transparent;
        border: 0px;
        padding: 0px !important;
    }

    .number-range button svg {width: 38px !important;height: 25px !important;}

    .number-range path.ci-primary {
        fill: $yellow;
    }
    .number-range button:hover,
    .number-range button:active,
    .number-range button:focus{
        background:transparent !important;
    }
    .section-content h2 {
        color: $yellow;
        font-weight: 700;
    }
    .selectPerson {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    div#ChildrenDetail td, div#ChildrenDetail th {
        border: 1px solid $darkBlue;
        padding: 5 10px;
    }
    div#ChildrenDetail .dateOfBirthField {
        background: transparent;
        border: 0;

    }
    .dateOfBirthField,
    .dateOfBirthField:active, 
    .dateOfBirthField:focus {
        background: transparent !important;
        border: 0 !important;
    }
    .package-range button {
        background: transparent;
        border: 1px solid;
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 20px;
        padding: 0;
        height: 25px;
        font-weight: 900;
        width: 29px;
        line-height: 25px;
    }
    .filedWraper {
        display: flex;
        flex-direction: column;
    }
    .date .react-date-picker__wrapper {
        background: #fff;
        height: 38px;
        border-radius: 3px;
    }
    .package-range input {
        background: transparent;
        border: none;
        color: #fff;
        font-size: 20px;
        width: 50px !important;
        height: 30px;
        font-weight: 900;
        text-align: center;
        margin-top: 0px !important;
        margin-right: -12px;
        position: relative;
        top: -4px;
        pointer-events: none;
        z-index: 0;
    }
    .customer-birthday {
        display: flex;
        flex-direction: column;
    }
    .customer-birthday .dofBirth {
        background: #fff;
    }
    p.custErrorMesg {
        margin-bottom: 0;
        position: absolute;
        top: 0;
    }

    .allGuestInfo .col {
        padding-top: 0px !important;
        position: relative;
    }
    .row.allGuestInfo {
        margin-left: 20px;
        margin-right: 10px;
        padding: 0 2px;
        border-left: 3px solid;
        margin-top: -30px;
        margin-bottom: 30px;
    }
    p.custErrorMesg .error {
        font-size: 14px;
        font-weight: 400;
    }
    .personInfo  .PITitel {
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        padding-bottom: 0px;
        margin-bottom: 5px;
        text-align: left;
        margin-right: 15px;
    }
    .package-viewDetail button {
        background: transparent !important;
        border: none !important;
        padding: 0;
    }
    .PIwraper .package-name {
        padding-bottom: 20px;
    }
    // End  Booking Package Style

    // Start Event left Side Invice
    .packageDetails {
        padding-top: 10px;
        margin-left: -50px;
    }

    .packageDetails .bookingInvice {
        padding-left: 0;
    }
    .bookingInvice li.roomRow {
        display: flex;
        justify-content: space-between;
    }
    .bookingInvice li.boldData{
        font-weight: 600;
    }
    .package_info .packageTitle {
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize;
    }
    .package_info .cust-subtotel span {
        text-transform: capitalize;
        font-size: 15px;
    }
    .orderTotel h3 {
        margin-bottom: 0px;
    }
    .package-selected .image-box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    .package-selected {
        background: $darkBlue;
        margin: 0 0px;
        margin-bottom: 30px;
    }
    .small, small {
        font-size: 16px;
        font-weight: 400;
    }
    .package-sub-row .content-box .package-name h5 {
        color: white;
        text-transform: uppercase;
    }
    .package-sub-row  .room-list-info ul {
        margin-top: 15px;
    }
    .package-sub-row .room-list-info ul li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
    }
    .package-sub-row .room-list-info ul li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
    }
    .package-name small {
        color: #fff;
    }
    .package-sub-row .room-list-info ul li span {
        color: white;
        font-size: 13px;
        font-weight: 700;
    }
    .package-sub-row .room-list-info ul {
        margin-top: 15px;
        max-width: 95%;
        padding-left: 0;
    }
    .package-sub-row .image-box {
        padding: 0px;
    }
    .package-name small {
        color: #fff;
    }
    .content-box .package-name h5 {
        color: white;
        text-transform: uppercase;
    }
    .roomsDetail select {
        min-width: 50px;
    }
    span.cust-badtype {
        width: 120px;
        text-align: center;
    }
    .col-md-9.content-box {
        padding: 20px;
    }
    button.roomDeleted {
        background: red;
        padding: 0 29px;
    }
    .package-sub-row .custbtn {
        background: transparent;
        color: #fff;
        padding: 6px 10px;
        border: 1px solid;
        border-radius: 4px;
        float: right;
    }
    .package-sub-row .custbtn:hover {
        color: $yellow;
        border-color: $yellow;
    }
    span.person-bed-typ {
        min-width: 92px;
    }
    .wraper-extra-nights {
        background: $darkBlue;
        margin: 020px;
        padding: 15px;
        color: #fff;
    }
    .cust-titel {
        color: #fff;
        font-size: 25px;
        text-transform: capitalize;
    }
    .wraper-extra-nights {
        background: $darkBlue;
        margin: 020px;
        padding: 15px;
        color: #fff;
    }
    .extra-night-dec {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        margin-right: -15px;
        margin-left: -15px;
    }
    .extra-night-dec > div {
        padding: 1px 14px;
    }
    .extra-night-dec p {
        padding-bottom: 0;
        color: #fff;
        margin-bottom: 0px;
    }
    select.extranights {
        margin-left: 15px;
    }
    .subHeading {
        font-size: 17px;
    }
    .smalheading {
        font-size: 20px;
        text-transform: unset;
    }
    .inner-wraper-addons-list {
        background: $darkBlue;
        margin: 20px;
        padding: 15px;
        color: #fff;
    }
    .inner-addons-list {
        display: flex;
        margin-bottom: 15px;
        align-items: center;
        margin-right: -15px;
        margin-left: -15px;
    }
    .inner-addons-list .inner-col {
        padding: 10px 10px;
    }
    .detail {
        margin-bottom: 0px;
    }
        .RoomEnd {
        margin-bottom: 15px;
        position: relative;
    }

    .RoomEnd:before {
        width: 80%;
        left: 10%;
        background: #0c2238;
        height: 4px;
        position: absolute;
        content: '';
        bottom: -8px;
        border-radius: 17px;
    }

    .subTotal span {
        font-size: 14px !important;
        color: #000 !important;
    }

    .subTotal {
        position: relative;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .subTotal:before {
        width: 100%;
        height: 2px;
        background: #d3d3d3;
        content: '';
        position: absolute;
        top: -7px;
        left: 0;
    }
    .field-box {
        border-left: solid 3px #0c2238;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 30px;
        margin-left:20px
    }
    .guest_type {
        font-size: 20px;
        text-transform: unset;
        font-weight: 700;
    }
    .BlockSeatsPopUp .modal-dialog {
        margin-left: auto;
        margin-right: 0;
        margin-top: auto;
        margin-bottom: 0px;
    }
    .BlockSeatsPopUp .TablePopUpBody .custTable {
        display: none;
    }
    .BlockSeatsPopUp .modal-content{
        border-radius: 0px;
    }
    .BlockSeatsPopUp .modal-header {
        background-color: #000;
        border-radius: 0;
    }
    .BlockSeatsPopUp .modal-body {
        min-height: 70vh;
    }
    .packageTitle{
        color: #222222;
        font-weight: 400;
        margin-top: 20px;
        letter-spacing: -.5px;
        font-size: 1.5625rem;
        text-transform: uppercase;
    }
    .trav-veiw-box {
        border-left: solid 3px #0c2238;
    }
    .trav-veiw-box ul {
        list-style-type: none;
    padding-left:15px;

    }
    .trav-veiw-box ul li{
        font-size: 16px !important;
        font-weight: 600;
        white-space: nowrap;
        color:#0c2238;
    }
    .trav-info-view {
        display: flex;
        margin-bottom: 30px;
    }
    .guestInfo {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        max-width: 98%;
        margin: 0 auto;
        gap: 40px;
        margin-top: 40px;
    }
	.addStaffPerformers .customer-middle-name {
        display: none;
    }
    .addStaffPerformers  .customer-birthday abbr {
        display: none ;
    }
   tr.highlited {
        background-color: yellow;
    }
    tr.highlited td {
       font-weight: 700;
    }
    .custDrop {
        position: relative;
        width: 100%;
        background: transparent;
        top: -60px;
        min-height: 50px;
        z-index: 14;
        margin-bottom: -40px;
    }
    .afterPArties .rommName {
        width: 109px;
    }
    .description h4 {
        font-size: 17px;
    }
    span.guestCounter {
        margin-right: 15px;
        font-size: 20px;
        font-weight: 600;
        color: $darkBlue;
    }
    .alignMiddle {
        align-items: center;
    }
    .tableInfoWraper label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .custBlockTable .form-check {
        display: flex;
        margin: 0;
        flex-direction: column-reverse;
        justify-content: center;
        padding-left: 0px !important;
    }
    .custBlockTable {
        max-width: 132px;
    }
    .tableRow button {
        margin-bottom: 0px !important;
    }
    .custBlockTable .form-check-label {
        float: unset;
        text-align: center;
    }
    .custBlockTable .form-check input {
        width: 30px;
        height: 30px;
        float: unset !important;
        margin: 0 auto !important;
    }
    .col.tableSerialNum {
        max-width: 40px;
    }
    .tableSerialNum span {
        font-weight: 600;
    }
    section.tableInfoWraper {
        margin-top: 50px;
    }
    .serialNumTable{
        display: none;
    }
    div#seatingmap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 96%;
        margin: 0 auto;
    }
    #seatingmap button {
        margin: 5px;
        border: none;
    }
    .circleTable button {
        border-radius: 50%;
        display: flex;
        height: 50px;
        width: 50px;
        justify-content: center;
        align-items: center;
        margin: px;
    }
    .blu-elite button,
    .blu-elite .tableType{
        background-color: transparent;
    }
    .blu-elite, .blu-elite .tableType {
        background: url('./../assets/images/bluEled.png') !important;
        background-size: 80% !important;
        background-repeat: no-repeat !important;
    }
    .react-datepicker-popper {
        width: 329px;
    }
    .executive button,
    .executive .tableType {
        background-color: #ee4d27;
    }
    .premium-plus button,
    .premium-plus .tableType {
        background-color: #0ee437;
    }
    .premium  button,
    .premium  .tableType {
        background-color: #e834e8;
    }
    .blockSeatsWraper {
        display: flex;
        flex-direction: column;
    }
    .deluxe  button,
    .deluxe  .tableType  {
        background-color: #20621d;
    }
    .classic  button ,
    .classic  .tableType {
        background-color: #e8fb3a;
    }
    .empty button,
    .empty .tableType {
        display: none;
    }
    .table-bar.empty {
        visibility: hidden;
    }
    .table-bar {
        display: flex;
        justify-content: center;
        position: relative;
    }
    span.seatsDots {
        position: absolute;
        background-color: transparent;
        z-index: -1;
    }
    span.seatsDots.circleTable {
        position: absolute;
        top: 0%;
        left: 7%;
        background-color: transparent;
        z-index: -1;
        width: 86%;
        height: 100%;
    }
    span.seatDot {
        background-color: #eee;
        width: 10px;
        height: 10px;
        border-radius: 20px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 7px;
    }
  .btnWraper {
        text-align: center;
        margin-bottom: 10px;
    }
    
    .btnWraper .btn {
        width: 40% !important;
    }
    .circleTable .seatDot{
    position: absolute;
    }
    .seatsDots.rectangleTable {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap:80%;
        left: 0;
    }
    .circleTable .seatPos_1 {
        top: 0%;
        right: 10%;
    }
    .circleTable .seatPos_2 {
        top:27%;
        right: -4%;
    }
    .circleTable .seatPos_3 {
        top: 60%;
        right: -2%;
    }
    .circleTable .seatPos_4 {
        top: 85%;
        right: 8%;
    }
    .circleTable .seatPos_5 {
        top: 100%;
        left: 48%;
    }
    .circleTable .seatPos_6 {
        top: 85%;
        left: 8%;
    }
    .circleTable .seatPos_7 {
        top: 60%;
        left: -10%;
    }
    .circleTable .seatPos_8 {
        top: 27%;
        left: -2%;
    }
    .circleTable .seatPos_9 {
        top: 0%;
        left: 10%;
    }
    .circleTable .seatPos_10 {
        top: -10%;
        left: 48%;
    }
    .circleTable .seatPos_10 {
        top: -10%;
        left: 48%;
    }
    .circleTable .seatPos_10 {
        top: -10%;
        left: 48%;
    }
    .seatsBooked {
        position: absolute;
    }
    .seatsBooked.Seats {
        background: #fff;
        width: 25px;
        height: 25px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        right: 0px;
        top: -2px;
    }
    .rectangleTable button {
        min-height: 61px;
    }
    .circleTable {
        height: 70%;
        width: 70%;
    }
    span.seatDot.bookedSeat {
        background-color: green;
        color: #fff;
        font-weight: 700;
    }
    span.seatDot.seatBlock {
        background-color: red;
        color: #fff;
        font-weight: 700;
    }
    .custTable {
        display: flex;
        height: 500px;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .rectangleTable{
        height: 80%;
        width: 50%;
    }
    .rectangleTable .tableType {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0 auto;
    }
    .TablePopUpBody .circleTable {
        height: 100%;
        width: 80%;
        display: flex;
        align-items: center;
    }
    .circleTable .tableType {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60%;
        width: 100%;
    }
    .event-tables {
        background: #0c2238;
        padding-top: 5px;
    }
    .stage {
        padding: 6px;
        background: #fff;
        margin: 13px;
        margin-top: 15px !important;
        display: flex;
        justify-content: center;
    }
    .table-bar button {
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
        font-size: 16px;
    }
    .custPopUpTable .event-tables {
        padding-bottom: 19px;
    }
    .TablePopUpBody .table-bar button {
        font-size: 16px !important;
    }
    .notSelectable {
        pointer-events: none;
        opacity: 0.5;
    }
    .selectable {
        cursor: pointer;
    }
    .tableDetail ul {
        list-style: none;
        padding: 0;
    }

    .tableDetail ul li {
        display: flex;
        justify-content: space-between;
    }
    hr.extra-space {
        height: 4px;
        background: #000 !important;
        opacity: 1;
    }
    button.react-date-picker__clear-button {
        display: none;
    }
    .dateOfBirth {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .childTypeSelect {
    text-transform: capitalize;
    }
    button.btnchild{
        padding: 0 25px !important;
    }
    .tableInputWraper {
        display: flex;
    }
    .tableInput input {
        width: 45px;
        margin-left: 11px;
        font-weight: 600;
        border: 0;
        pointer-events: none;
    }
    .custDataTimePicker .react-datepicker-popper {
        width: 328px;
    }
    .allGuestInfo .react-datepicker-wrapper input {
        height: 38px;
        padding: 0px 14px;
        border-radius: 5px;
    }
    .selected input {
        width: 50px;
        border: 1px solid #e9ecef;
        text-align: center;
        background-color: #e9ecef;
        padding: 4px 4px;
        margin-right: -12px;
        pointer-events: none;
    }
    .tableDetails {
        margin-top: 26px;
    }
    .custTableNumber {
        font-weight: 600;
        font-size: 40px;
    }
    p.totalTableHeading {
        font-weight: 600;
    }
    .seat-range .input-group-text {
        color: #495057 !important;
        font-size: 20px;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        cursor: pointer;
    }
    .seat-range {
        display: flex;
        margin: 0 auto;
    }
    .alertWraper .alert {
        color: red;
        padding: 25px 0 0 0;
        font-weight: 400;
    }
    .notesWraper:before {
        position: absolute;
        width: 100%;
        height: 3px;
        background:$darkBlue;
        top: -9px;
        content: '';
    }
    .notesHeading {
        padding-top: 16px;
    }
    .row.notesWraper:after {
        content: '';
        width: 100%;
        height: 4px;
        position: absolute;
        background: $darkBlue;
        bottom: -14px;
    }
    .react-datepicker__month-year-dropdown {
        height: 300px;
        overflow: scroll;
    }
    span#timer {
        display: inline-block;
        width: 60;
        max-width: 55px;
    }
    p.bookingDisabled {
        color: red;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
    }
    .visaWraperInner {
        padding-top: 20px;
    }
    .notesWraper {
        position: relative;
    }
    .notesHeading {
        font-size: 30px;
        font-weight: 700;
    }
    .alertWraper .alert .alertHeading {
        font-size: 20px;
        font-weight: 700;
    }
    .snannierviceAddMoreBtn{
        margin-top: 10px;
    }
    .radioButtonWraper {
        display: flex;
        gap: 20px;
    }
    .input-group-text.seat-remove {
        border-radius: 0px 7px 7px 0px;
    }
    .input-group-text.seat-add {
        border-radius: 7px 0px 0 7px;
    }
    .snanniervice .input-group {
        gap: 10px;
        align-items: end;
    }
    .termAndCondition {
        height: 300px;
        background: #fff;
        padding: 30px 30px;
        overflow-y: scroll;
    }
    .tCHeading {
        font-weight: 700;
        font-size: 23px;
        margin-bottom: 15px;
    }
    .event-loader {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        z-index: 1000000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .event-loader img {
        display: flex;
        width: 100px;
    }
    .bg-trans .topHeader {
        display: none;
    }
    .logoTest {
        padding-bottom: 0px;
    }
    .container.thankYouDescription {
        height: 60vh;
        text-align: center;
    }
    .icinWraoerThankYou svg {
        color: $yellow;
        width: 100px !important;
        height: 100px !important;
        padding: 15px;
        border: 5px solid $yellow;
        border-radius: 50px;
        margin-bottom: 40px;
    }
    .container.thankYouDescription p {
        font-size: 30px;
    }
    .container.thankYouDescription {
        height: 40vh;
        text-align: center;
    }
    .blockTableDesc {
        font-size: 12px;
        color: red;
    }
    .rangeDate {
        position: relative;
        z-index: 8;
    }
    .custDrop.hide>div {
        display: none;
    }
    .input-group-text svg {
        pointer-events: none;
    }
    .TableSelectFromAdmin .modal-dialog {
        max-width: 600px;
    }
    .tableBopup .modal-dialog {
        max-width: 90%;
    }
    .eventInnerWraper {
        padding: 30px 13px;
        position: relative;
    }
    .eventGridOuterRaper{
        margin-bottom: 30px;
    }
    .eventInnerWraper:before {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        position: absolute;
        background-color: rgba(44, 56, 74, 0.8)
    }
    .eventContent {
        position: relative;
        z-index: 3;
    }
    .eventTitel h3 {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
        padding: 20px 0;
        text-align: center;
    }
    .eventNavigation .btn {
        max-width: 100%;
        font-weight: 700;
    }
    a.addNewEvent svg {
        width: 80px !important;
        height: 80px !important;
        color: $darkBlue;
    }
    .addNewEventinnerWraper {
        border: 5px solid $darkBlue;
        padding: 17px;
        text-align: center;
        min-height: 170px;
    }
    .addNewEventTitel h3 {
        color:  $darkBlue;;
        font-size: 20px;
        font-weight: 700;
        text-decoration: none;
    }
    .eventNavigation {
        display: flex;
        justify-content: space-around;
    }
    .addNewEventinnerWraper:hover{
        opacity: 0.5;
    }
    a.addNewEvent {
        text-decoration: none;
    }
    // End Event left Side Invice
    section._home_banner {
        height: 55vw;
    }
    ._package_price {
        font-size: 16px;
        font-weight: 600;
        position: relative;
        padding: 0;
        margin: 0;
        text-align: center;
    }
    table.cust_pack_1994 {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        border: 1px solid;
    }
    table.cust_pack_1994 tr {
        border-bottom: 1px solid;
    }

    table.cust_pack_1994 tr th {
        padding: 4px 0px;
        border-right: 1px solid;
    }
    table.cust_pack_1994 tr td {
        padding: 4px 0px;
        border-right: 1px solid;
    }
    ul.blog_inner_room_detail {
        list-style: none;
        margin-left: 12px;
        display: flex;
        flex-wrap: wrap;
    }
    li.blog_room_detail_list {
        line-height: 30px;
        display: flex;
        align-items: center;
        flex: 1 1 50%;
    }
    .modal-header {
        background-color: $yellow;
        color: $white;
    }
    h5.modal-title {
        font-weight: 600;
    }
    .modal-header button.btn.btn-close {
        background-color: $white;
    }
    ul.blog_inner_room_detail i {
        color: $darkBlue;
        font-size: 19px;
        margin-right: 15px;
    }
    .cilChevronRight{
        background-color:$white !important;
    }
    .errormessage {
        background-color: $white !important;
        margin: 20px 0px;
    }
    .teramAndcondition {
        text-align: right;
    }
    .Slider .carousel-item img {
        border-radius: 50% !important;
        margin: 0 auto;
        max-width: 400px;
    }
    .Slider .carousel-caption{
        position: unset !important;
    }
    .headingMediumSize{
        font-size: 36px;
    }
    .largeText{
        font-size: 26px;
    }
    .extrLargeFontSize{
        font-size: 56px;
    }
    .largeFontSize{
        font-size: 40px;
    }
    .fontColorYellow{
        color: $yellow;
    }
    .fontColorWhite{
        color: $white;
    }
    .bgWhite{
        background-color: $white;
    }
    .addNewOrder #progress li a {
        padding: 14px 0px 14px 33px !important;
    }
    .resp-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
    }

    .resp-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
    .Slider .carousel-control-prev,
    .Slider .carousel-control-next {
        background:$yellow;
        width: 50px;
        height: 50px;
        position: absolute;
        top: calc(50% - 20px);
        border-radius: 50%;
    }
    .Slider .carousel-control-prev {
        left: 5%;
    }
    .Slider .carousel-control-next {
        right: 5%;
    }
    .Slider {
        position: relative;
    }
    .Slider:before {
        position: absolute;
        width: 70%;
        height: 3px;
        content: '';
        bottom: 0;
        background: $yellow;
        left: 15%;
        border-radius: 50%;
    }
    .headingWithborder {
        position: relative;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .headingWithborder:before {
        position: absolute;
        content: "";
        width: 200px;
        height: 3px;
        left: calc(50% - 100px);
        bottom: 0px;
        background-color: #fff;
    }
    .chackinoutTitle {
        font-size: 14px !important;
    }
    .chackInOutdate {
        font-weight: 700;
    }
    .simplebar-content a.nav-link {
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
    }
    ul.nav-group-items a {
        padding-left: 45px !important;
    }
    ul.inner_room_detail {
        display: table;
        border-collapse: collapse;
        width: 60%;
        color: #222222;
        list-style: none;
        margin-left: 0;
        padding: 0;
        margin-bottom: 0;
        padding-bottom: 100px;
    }
    li.room_detail_list {
        display: table-row;
        padding: 0;
        border: 1px solid #ededed;
        font-size: 1rem;
    }
    span.first-col.fw-bold {
        padding-left: 0.25em;
        position: relative;
        border-right: 1px solid #ededed;
        display: table-cell;
    }
    li.room_detail_list span {
        padding: 10px 10px !important;
    }
    .custSpiner {
        width: 100%;
        justify-content: center;
        display: flex;
        height: 100vh;
        align-items: center;
    }
    .TableSelectFromAdmin  input#seatSelectedModal {
        height: 100%;
    }
    ul.nav.nav-tabs a {
        cursor: pointer;
    }
    .mapWraper .tableDetails {
        margin-top: 0px;
    }
    .cusNavMyAccountWithIcon {
        border: 1px solid;
        padding: 15px;
        margin-bottom: 0px;
        border-radius: 10px;
        gap: 20px;
    }
    .cusNavMyAccountWithIcon  .iconWrapper svg.icon {
        width: 60px !important;
        height: 60px !important;
        color: $yellow;
    }
    .myAccountCotentInnerWraper a {
        text-decoration: none;
        color: #000;
    }
    .bgBlack{
        background-color: #000;
    }
    .galleryImageWraper img {
        width: 100%;
        padding: 10px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    img.d-block.rosterImage {
        padding: 23px;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .galleryImageWraper .col {
        padding: 0px !important;
    }
    .page_banner {
        padding: 150px 0 50px 0;
    }
    .col.packagImageList > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .addStaffPerformers .travelar_wraper > .row {
        max-width: calc(100% - 25px);
        margin: 0 auto !important;
    }
    .travelar_wraper   > .row >.col{
        position: relative;
    }
    .addStaffPerformers p.custErrorMesg {
        margin-bottom: 0;
        position: absolute;
        top: 3px;
        right: 31px;
    }
    .addStaffPerformers .field-box {
        margin-left: 0px !important;
        border: 0;
    }
    .addStaffPerformers  .row.allGuestInfo {
        margin-left: 15px;
        border-left: 0px solid !important;
    }
    .addStaffPerformers   .travelar_wraper .form-label {
        margin-top: 0;
        margin-bottom: 0;
    }
    .searchForm {
        background: #000;
        margin-top: 15px;
        margin-bottom: -8px;
        width: calc(100% - 24px);
        margin-right: auto;
        margin-left: auto;
    }
    .orderCardInner svg {
        width: 50px !important;
        height: 50px !important;
    }
    .searchBarcodes > form .form-label {
        color: #fff;
    }
    .align-end{
        align-items: end;
    }
    .searchFormStyle2 {
        background: #000;
        margin-top: 0px;
        margin-bottom: -8px;
        width: calc(100% - 0px);
        margin-right: auto;
        margin-left: auto;
    }
    .packageList.even {
        flex-direction: row-reverse;
    }
    .addStaffPerformers .react-datepicker__input-container input {
        border: 1px solid #b1b7c1;
        border-radius: 0.375rem;
        padding: 0.375rem 0.75rem;
        width: 100%;
    }
    .custRemoveTable {
        width: 100% !important;
    }

    button.RemoveTable {
        width: 100%;
        background: red;
        color: #fff;
        margin-top: 12px !important;
    }
    .addPromotionPopUp .modal-dialog {
        max-width: 1000px;
    }
    .addGuestPopUp .modal-dialog {
        max-width: 80%;
    }
    .addStaffPerformers .react-datepicker-wrapper {
        width: 100%;
    }
    .addStaffPerformers .col.customer-birthday.col-sm-9 {
        display: none;
    }
    .packageList.even {
        flex-direction: row-reverse;
    }
    .iconContact svg {
        width: 100px !important;
        height: 100px !important;
        margin-bottom: 30px;
        color: $yellow;
    }
    .topborder {
        border-top: 2px solid $white;
    }
    .contactUsDescription p {
        font-size: 18px;
    }
    .contactUsDescription a {
        font-size: 18px;
        color: $yellow;
    }
    .packagImageList img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .custContactForm {
        max-width: 820px;
        margin: 0 auto;
        padding: 50px;
        background: $darkBlue;
        color:#fff;
        border-radius: 10px;
    }
    .dropdown-menu {
        background: $darkBlue;
    }
    .dropdown-item.active, .dropdown-item:active{
        background-color: $yellow !important;
    }
    .noteceNotAvilable {
        color: red;
    }
    .mainHeader  .nav-link.active{
    color: $yellow  !important;
    }
    .mainHeader  .btn.active {
        color: #fff !important;
    }
    .nav-link:hover{
        color: $yellow  !important;
        }
        .nav-item .btn:hover {
            color: #fff !important;
        }
    .custContactForm  h2,
    .custContactForm label {
        color:#fff;
    }
    .packagImageList {
        padding: 0;
    }
    .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
        color: $white !important;
        background-color: $yellow;
        border-color: $yellow;
    }
    section.booking_event_banner.page_banner {
        padding-top: 0;
    }
    .upgrateTotal p {
        display: flex;
        justify-content: space-between;
    }

    .navbar-collapse ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .navbar-collapse .header-nav.nav.justify-content-center li {
        display: inline-block;
        /*margin-right: 20px;*/
        position: relative;
    }
    .navbar-collapse ul.dropdown-menu {
        display: none; 
        position: absolute;
        background-color: #154171;
        min-width: 160px;
        z-index: 1;
    }
    .custTiPicker .react-datepicker-wrapper input {
        width: 100% !important;
    }
    .travelar_wraper .col {
        padding-left: 5px;
        padding-right: 5px;
    }
    /* .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after,
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before
    {
        left:39px;
    } */
    .custTiPicker {
        position: relative;
    }
    .custTiPicker .react-datepicker-popper{
        transform:unset !important;
    }
    .travelar_wraper .form-label {
        margin-top: 15px;
    }
    .upgrateCell button {
        margin: 2px !important;
    }
    .navbar-collapse ul.dropdown-menu a {
        color: #fff;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }
    .requireField {
        position: relative;
    }
    .requireField .custErrorMesg {
        margin-bottom: 0;
        position: absolute;
        top: 5;
        right: 25px;
        font-weight: 700;
    }
    .requireField .custErrorMesg .error {
        font-weight: 700;
    }
    textarea.form-control.clientPoratMessage {
        min-height: 200px;
    }
    .custTiPicker .react-datepicker-popper {
        transform: unset !important;
        width: 100% !important;
        top: 36px !important;
    }
    .navbar-collapse ul.header-nav.nav.justify-content-center li:hover .dropdown-menu {
        display: block;
    }
    .navbar-collapse ul.dropdown-menu a:hover{
        background-color: #154171;
    }
    .orderCardInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #000;
        padding: 30px 15px;
        border-radius: 5px;
        height: 100%;
        width: 100%;
    }
    .orderCardInner svg{
        color:#fff;
    }
    .orderCardInner .description {
        text-align: center;
        color: #fff;
    }

    .col.orderCard {
        margin-bottom: 25px;
    }
span.sixDiget {
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        background: #fff;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border: 1px solid;
        padding: 6px;
    }
	.pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      
      .pagination button {
        padding: 8px 12px;
        border: none;
        background-color: $darkBlue;
        color: white;
        cursor: pointer;
        border-radius: 5px;
      }
      
      .pagination button:disabled {
        background-color: #ccc;
        cursor: not-allowed;
      }
    @media screen and (max-width:1400 ) {
        #progress li a {
            padding: 14px 15px 14px 36px !important;
        }
    }
    @media screen and (max-width: 991px) {
        .header-nav{
            flex-direction: column;
        }
        .logoForDasktop{
            display: none;
        }

        }
        @media screen and (min-width: 480px) {
            // Footer Css
            .footerNavBar .navbar-nav {
                flex-direction: row;
            }
            .footerNavBar a.nav-link {
                padding: 10px 46px !important;
            }
            // End Footer Css
        }
        .booking_event_banner h1,
        .page_banner h1 {
            padding: 181px 0 60px;
            color:$white;
            text-align: center;
        }
        .pointerEventNone {
            pointer-events: none;
        }
        .paymentBtn {
            margin-left: auto;
            position: relative;
            bottom: -78px;
        }
        .page-link {
            cursor: pointer;
        }

        .orderEdit  .form-label {
            font-size: 14px !important;
            font-weight: 700;
        }
        .orderEdit .row > div {
            padding: 4px;
        }
        .orderEdit .guestWraper {
            display: flex;
        }
        .orderEdit .guestWraper input {
            border-radius: 0;
        }
        .orderEdit .guestWraper .subBtn {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
        .eventAdminWraper .entryTiteladmin {
            font-size: 16px;
        }
        .eventAdminWraper h2,
        .eventAdminWraper h3
        {
            font-size: 16px;
            font-weight: 700;
        }
        .eventAdminWraper {
            font-size: 14px;
        }
        .eventAdminWraper th {
            font-size: 14px;
        }
        .eventAdminWraper .nav-link {
            font-size: 14px !important;
            text-wrap: pretty;
        }
        .reportData thead {
            background: #000;
            color: #fff;
        }  
        .ReportDataWraper{
            overflow: scroll;
            height: 600px;
        }
        .reportData thead {
            background: #000;
            color: #fff;
            position: sticky;
            top: 0;
            z-index: 7;
        }
        .reportData tr {
            border: 1px solid;
        }
        .reportData thead tr {
            border: 1px solid #000;
        }

        .reportData tbody tr:nth-child(even):before1 {
            content: '';
            left: 0;
            top: 0;
            background-color: #e3e3e3;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }
        .reportData tbody tr:nth-child(even)
        {
            width: 100%;
            position: relative;
            z-index: 6;
        }
        .reportData tbody td {
            font-size: 13px;
            padding: 3px;
        }
        .orderEdit .guestWraper .addBtn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
        .roomHeadingWraper {
            background: $yellow;
            margin: 15px 0;
            color: $darkBlue;
            text-align: center;
            padding: 0px !important;
        }
        .orderview .roomHeadingWraper, .orderview .packageHeadingWraper {
            margin-top: 0px;
        }
        .orderview  section.packegDetails {
            border: 1px solid;
            margin: 30px 0px;
            border-radius: 15px;
        }
        .col.custColHalf {
            flex: .5;
        }
        .guestDetail {
            margin-bottom: 5px;
        }
        .OrderDetailForm{
            padding-bottom: 100px;
        }
        .packageHeadingWraper {
            background: $darkBlue;
            margin-top: 22px;
            color: #fff;
            text-align: center;
            padding: 0px !important;
        }
        section.packegDetails {
            overflow: hidden;
        }
        .packageHeadingWraper .packageType {
            font-weight: 700;
            font-size: 24px;
            margin: 0;
            line-height: 50px;
            margin: 0px !important;
        }
        .roomHeading h3 {
            font-size: 25px;
            line-height: 50px;
            margin: 0px !important;
        }
        .dofBirth {
            height: 38px;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid var(--cui-input-border-color, #b1b7c1);
        }
        .dofBirth  .react-date-picker__wrapper {
            border: none;
        }
        .addNewRoomWrapper {
            text-align: end;
        }
        .addNewRoomBtnHide{
        display: none; 
        }
        .tableInputWraperCheckBox {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .seatBlockWraper {
            position: relative;
        }
        .seatBlockWraper input#blockSelectedModal {
            position: absolute;
            background: #fff;
            border: 0;
            width: 45px;
            top: -3px;
            right: -4px;
        }
        .addNewOrder .col.tableDetails.mb-2 {
            flex: 5.5;
        }
        
        .addNewOrder .col.custTable {
            flex: 4.5;
        }
        .seatBlockWraper .seatRangeWraper {
            padding-right: 27px;
        }
        .splitPAymentWraper .input-group {
            max-width: 413px;
        }
        .seatBlockWraper  input#seatSelectedModalWithBlock {
            height: 44px;
        }
        .upgradeBooking .guestWraper {
            display: flex;
            max-width: 90px;
            max-height: 25px;
        }
        .upgradeBooking .guestWraper .subBtn {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            padding: 0 8px;
        }
        .upgradeBooking .guestWraper .addBtn {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            padding: 0 8px;
        }
        .upgradeBooking .guestWraper input {
            border-radius: 0;
        }
        .deletBtnWraper {
            width: 50px;
        }
        .tabFooter {
            max-width: 90%;
            margin: 0 auto;
        }
        table.custTransection td, table.custTransection th {
            text-align: center;
            border: 1px solid;
            padding: 5px 5px;
        }
        .esignHeading h2 {
            padding: 20px 10px !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            border-bottom: 0;
            margin-bottom: 0;
            background: $darkBlue;
            color: $white;
        }
        .orderEdit h1 {
            font-size: 30px;
            font-weight: bold;
        }
        .custTitels {
            padding: 7px 5px;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            color: $white;
            background: $yellow;
        }
        .checkOutbtn {
            background: red;
            color: $white;
            font-weight: 700;
        }
        .orderEdit .wraperVisa:before {
            position: absolute;
            width: 100%;
            height: 3px;
            background: #154171;
            top: 10px;
            content: "";
        }
        .orderEdit .wraperVisa {
            position: relative;
        }
        .cusRoomOnly .tableNum {
            display: none;
        }
        .searchBarcode{
            padding-top:50px ;
            padding-bottom:50px ;
        }
        .emailsSendButton  button, 
        .emailsSendButton  a {
            font-size: 14px;
        }
        .addTicketPopup .col .custErrorMesg {
            right: 15px;
        }
        .addTicketPopup .col{
            position: relative;
        }
		.barcodeReadBtn{
            z-index: 999999999999999;
    		position: relative;
        }
		.barcodeReadBtn:hover{
            background-color: $darkBlue !important;
        }
        .searchBarcode .searchBarcodeInner {
            max-width: 1100px;
            padding: 20px;
            border: 1px solid;
            margin: 0px auto;
            display: block;
        }
        .orderEdit .allServices {
            border: 1px solid;
        }
        .orderEdit .allServices  .notesHeading {
            font-size: 20px;
            margin-bottom: 17px;
        }
        .orderEdit .visaWraperInner {
            padding-top: 0px;
        }
        .comingSoonPage .logo {
            max-width: 300px;
        }
        .comingSoonPage .custcomSoonHead {
            font-size: 40px;
            font-weight: 700;
            margin-top: 50px;
            text-align: center;
        }
        .dragAndDropFloor .custTables {
            flex: 0 0 6.5%;
            border:  0.01vw solid;
            margin: 0.01%;
            position: relative;
        }
        .eventBlockHandeler {
            position: relative;
            overflow: hidden;
        }
        .zoomINandOutNAvegation {
            position: absolute;
            top: 10px;
            left: 10px;
            display: flex;
            flex-direction: column;
            z-index:99;
        }
        .zoomINandOutNAvegation button {
            font-size: 23px;
            font-weight: 700;
            padding: 5px 10px;
        }
        .dragAndDropFloor .numberOf {
            position: absolute;
            top: 0;
            left: 50%;
            z-index: 1;
            background: red;
            color: #fff;
            width: 20px;
            border-radius: 50%;
            height: 20px;
            justify-content: center;
            align-items: center;
            display: flex;
            font-size: 14px;
            font-weight: 700;
        }
        .dragAndDropFloor .circleTable {
            height: 60px !important;
            width: 60px !important;
        }
        .guestStyle {
            cursor: move;
            border: 1px solid;
            font-size: 10px;
            margin: 1px;
            background: $darkBlue;
            color: #fff;
            line-height: 12px;
            padding: 2px;
        }
        .receivables  tr > th:last-child,
		.receivables  tr > td:last-child {
				text-align: right;
				padding-right: 15px;
			}
        .rectangleTable {
            height: 80px  !important;
            width: 70px!important;
        }
        .comingSoonPage {
            display: flex;
            align-items: center;
            height: 100vh;
            width: 100vw;
            flex-direction: column;
            justify-content: center;
        }
        .sidebar.sidebar-fixed {
            background: $darkBlue !important;
        }
        .showBookingType {
            font-weight: 700;
            text-transform: capitalize;
        }
        .dragAndDropFloor {
            display: flex;
            flex-wrap: wrap;
        }
          .wrapper {
            position: relative;
            z-index: 1029;
        }
		.camInputarea{
           position: relative;
            z-index: 1029;
		}

    @media screen and (min-width: 992px) {
    .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header ,.custLogoHeader{
        display: none;
    }
    .navbar-toggler{
        display: none;
    }
    .navbar-collapse {
        display: block !important;
        flex-basis: auto;
    }
    }
    @media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
    }
    @media (min-width: 1400px){
        .container, .container-lg, .container-md, .container-sm, .container-xl {
            max-width: 1350px;
        }
        }
        @media (min-width: 768px){
			.mobileBtn{
			display: none !important;
			}
            .header-sticky .nav-item.custFloatingBtn {
                display: none !important;
            }
            
            .header-sticky.bg-trans .nav-item.custFloatingBtn {
                display: block !important;
                position: fixed !important;
                top: 37px;
                right: 18px;
            }
        }
    @media (max-width: 1024px){
        div#seatingmap {
            max-width: 100% !important;
            width: 100% !important;
        }
        .dragAndDropFloor .circleTable {
            height: 30px !important;
            width: 55px !important;
        }
        .dragAndDropFloor .custTables {
            flex: 0 0 calc(6.6% - 5px);
            min-width: calc(6.6% - 5px);
        }
        #seatingmap button {
        margin: 0 !important;
        border: none;
    }
        .rectangleTable {
            height: 60px !important;
            width: 40px !important;
        }
    }

    @media (max-width: 930px){
        .dragAndDropFloor .custTables {
            flex: 0 0 calc(6.6% - 2px);
            min-width: calc(6.6% - 2px);
        }
        .dragAndDropFloor .circleTable {
            height: 30px !important;
            width: 40px !important;
        }
        .circleTable button {
            height: 38px;
            width: 35px;
        }
    }
    @media (max-width: 767px){
		 .sidebar {
           left:300px;
        }
		.sidebar-backdrop{
			width: 0vw;
        	height: 0vh;
		}
		.searchBarcodeInner .input-group .react-datepicker-wrapper{
            max-width: 85% !important;
        }
        .container.topHeader {
            display: none;
        }
        .homePage.headerTransparent {
            margin-top: 0;
        }
        .mainHeader {
            background: $darkBlue;
        }
        .header-sticky.bg-trans .custFloatingBtn{
            display: none;
        }
        .mobileLogo {
            height: 45px;
        }
        ul.blog_inner_room_detail {
            margin: 0;
            padding:0;
        }
        .packagDese.card-body {
            margin-bottom: 15px;
        }
        .extrLargeFontSize {
            font-size: 25px;
        }
        .largeText {
            font-size: 18px;
        }
        .largeFontSize,.headingMediumSize {
            font-size: 20px;
        }
        h2.event_title, .event_title.h2 {
            padding-top: 8px;
        }
        #progress li a:before,
        #progress li a:after{
            content: unset;
        }
        #progress li.bar {
            flex: 0 0 27%;
            padding: 0px;
            margin: 2px !important;
            text-align: center;
        }
        #progress li.bar a {
            font-size: 14px !important;
            margin: 0px !important;
        }
        #progress li.bar a {
            font-size: 14px !important;
            margin: 0px !important;
            padding: 5px;
            font-weight: 400;
        }
        .sidebar-content {
            padding-top: 22px;
        }
        .packageWraper {
            flex: 100%;
        }
        .packageInfo {
            flex-direction: column;
        }
        .PIwraper {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
        .inner-wraper-addons-list {
            margin-left: 0;
            margin-right: 0;
        }
        .inner-addons-list .inner-col {
            padding: 0px 5px;
        }
        .smalheading {
            font-size: 14px;
            text-transform: unset;
        }
        p.detail {
            font-size: 12px;
        }
        .cust-titel {
            font-size: 16px;
        }
        .extra-night-dec > div {
            padding: 1px 5px;
        }
        .wraper-extra-nights label {
            font-size: 12px;
        }
        .extra-night-dec p {
            font-size: 12px;
        }
        .extra-night-dec {
            align-items: start;
        }
        .wraper-extra-nights {
            margin: 0;
        }
        .travelar_wraper  .col {
            flex: 0 0 100%;
            flex-direction: row;
        }
        .react-datepicker-wrapper {
            width: 100%;
        }
        .travelar_wraper .form-label {
            margin-top: 0px;
        }
        .col.customer-birthday {
            flex-direction: column;
        }
        .field-box {
            margin-left: 0;
        }
        .row.allGuestInfo .col {
            flex: 0 0 100%;
            padding: 5px;
        }
        .trav-veiw-box ul li {
            font-size: 14px !important;
            font-weight: 400;
        }
        .trav-info-view {
            margin-bottom: 0px;
        }
        .tCHeading {
            font-size: 16px !important;
        }
        .paymentSectionInner .row {
            flex-direction: column;
        }
        main.pageContent.headerTransparent {
            margin-top: 150px !important;
        }
        .booking_event_banner h1, .booking_event_banner .h1, .page_banner h1, .page_banner .h1 {
            padding: 50px 0 60px;
            font: 35px;
        }
        section.booking_event_banner.page_banner {
            padding-bottom: 0;
        }
        .custContactForm {
            padding: 15px;
        }
        ul.room-ul.selectedPackForm {
            overflow: scroll;
        }
        .PackageDetails{
            overflow-x: auto;
        }
        .table.table-borderless {
            overflow: auto;
        }
        .guestDetail .col {
            flex: 0 0 100% !important;
            margin-bottom: 7px;
        }
        div#seatingmap {
            min-width: 1000px;
            overflow-x: auto !important;
        }
        .table-responsive{
            overflow-x:auto ;
        }
        ul.room-ul.selectedPackForm {
            min-width: 650px;
        }
        .room-list-info {
            overflow-x: auto;
        }
		        .GuDetailInMobilView {
            flex-direction: column;
        }
        
        .GuDetailInMobilView .btn {
            background: transparent;
            color: blue;
            border: navajowhite;
            padding: 0;
            text-decoration: underline;
            margin: 0 !important;
        }
          .GuDetailInMobilView p{
            margin-bottom: 0;
        }
        .searchBarcode .searchBarcodeInner {
            padding: 0px;
            border: 0;
        }
        .searchBarcode .pclWrapper {
            margin-bottom: 10px;
        }
        .searchBarcode {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .packageDetails .col {
            overflow: scroll;
        }
        .allEvents .row {
            flex-direction: column;
        }
        .EventOrderDeshboard .row {
            flex-direction: column;
        }
    }